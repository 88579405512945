<template>
  <div>
    <section class="message task-created" :class="{ selected }">
      <section>
        <hub-button @click="onIconClick">
          <hub-icon :name="row.milestones.length ? 'sign-direction' : 'plus'" size="xlg" />
        </hub-button>
        <div>
          <h4>{{ row.payload.next.name }}</h4>
          <div class="references">
            <span>{{ formattedReferences }} </span>
          </div>
          <div class="assignees">
            <span class="attorneys">
              {{ formattedAttorneys }}
            </span>
          </div>
        </div>
        <div class="metadata">
          <div>
            {{ new Date(row.timestamp).toLocaleString() }}
          </div>
          <div v-if="row.payload && row.payload.next.dueAt">
            Due At <label>{{ new Date(row.payload.next.dueAt).toLocaleDateString() }}</label>
          </div>
          <div v-else>
            <span class="attorneys">No due date</span>
          </div>
        </div>
      </section>
    </section>
    <milestone-create-modal v-if="selectedRow" :selected="selectedRow" @close="selectedRow = null" @created="onCreate" />
  </div>
</template>

<script>
import Icon from '@/components/common/MdIcon';
import Button from '@/components/common/Button';
import MilestoneCreateModal from './MilestoneCreateModal.vue';

export default {
  components: {
    'hub-button': Button,
    'hub-icon': Icon,
    MilestoneCreateModal
  },
  props: {
    row: {
      type: Object,
      default: () => ({})
    },
    selected: {
      type: Boolean
    }
  },
  data() {
    return {
      selectedRow: false
    };
  },
  computed: {
    formattedReferences() {
      const uniqueEntries = new Set(this.row.payload.next.invention.references.map(r => r.split(':')[1]));
      return Array.from(uniqueEntries).join(', ');
    },
    formattedAttorneys() {
      const attorneys = this.row.payload.next.invention.responsibleAttorneys.filter(ra => ra) || [];
      return attorneys.join(', ');
    }
  },
  methods: {
    onIconClick() {
      if (this.row.milestones.length) {
        this.$emit('infoclicked');
      } else {
        this.selectedRow = this.row;
      }
    },
    onCreate() {
      this.$emit('milestoneCreated');
      this.selectedRow = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.message.task-created {
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  height: 100%;
  background-color: var(--theme-surface);
  border-radius: 3px;
  padding: 0.75rem 1rem 0.75rem 1.25rem;
  grid-gap: 0.25rem;
  margin-bottom: 10px;

  &.selected {
    background-color: var(--theme-on-secondary);
  }

  label {
    font-weight: 600;
  }

  .attorneys {
    font-size: 0.8rem;
    font-style: italic;
  }

  > section {
    display: grid;
    grid-template-columns: 50px minmax(0, 1fr) max-content max-content;
    grid-template-rows: max-content;
    grid-gap: 0.5rem;
    align-items: center;

    h4 {
      font-weight: 700;
    }
  }

  .metadata {
    text-align: right;
    font-size: 0.75rem;
  }

  i {
    cursor: pointer;
  }
}
</style>
